import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

export interface AppFooterCompanyProps {
  className?: string
}

declare global {
  interface Window {
    OneTrust: any
  }
}

const AppFooterCompany = ({ className }: AppFooterCompanyProps) => {
  return (
    <AppFooterCompanyStyled className={`${className} footer-company`}>
      <Link to="/about-us/" className="footer-company__title">
        Company
      </Link>
      <div>
        <Link className="footer-company__link" to="/about-us/">
          About Us
        </Link>
        <Link className="footer-company__link" to="/careers/">
          Careers
        </Link>
        <Link className="footer-company__link" to="/news/">
          News
        </Link>
        <Link className="footer-company__link" to="/data-security-privacy/">
          Security &amp; Data Privacy
        </Link>
        <Link className="footer-company__link" to="/support/">
          Support
        </Link>
        <a
          className="footer-company__link"
          href="https://investors.smartrent.com/overview/default.aspx"
          target="_blank"
          rel="noopener noreferrer"
        >
          Investors
        </a>
        <button
          className="footer-cookie__link"
          onClick={() => window.OneTrust.ToggleInfoDisplay()}
        >
          Manage Cookie Preferences
        </button>
        {/* <a
          className="footer-company__link"
          href="https://res.cloudinary.com/smartrent-com/image/upload/v1713977372/pages/footer/SmartRent_Media_Kit_April_2024_hp75iq.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Media Kit
        </a> */}
      </div>
    </AppFooterCompanyStyled>
  )
}

export default AppFooterCompany

const AppFooterCompanyStyled = styled.div`
  padding: ${rem(16)} 0 0;

  @media ${media.stUp} {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    width: var(--st-column-default);
  }

  .footer-company__title {
    padding: ${rem(8)} 0;
    font-weight: bold;
    color: var(--color-gray200);
  }

  .footer-company__link {
    padding: ${rem(8)} 0;
    line-height: 150%;
    color: var(--color-gray200);
  }

  .footer-cookie__link {
    padding: ${rem(8)} 0;
    text-align: left;
    font-size: 0.875rem;
    color: var(--color-gray200);
  }
`
