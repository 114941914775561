import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import { media } from "@sr/common"
import { BrandingSecondary } from "@sr/gatsby-theme-ui"
import { Link } from "gatsby"
import { OtherNavInfo } from "../navData"

import NavItemDesktop from "./NavItemDesktop"

export const headerLargeBreakpoint = media.stMidUp
export const headerHeight = 64

export interface NavigationNavData {
  content: string
  featured: string | boolean
}

const AppHeaderDesktopContainer = ({ navigation, className }: any) => {
  return (
    <AppHeaderDesktopContainerStyled role="banner" className={className}>
      <div className="header__brand">
        <Link to="/">
          <BrandingSecondary
            className="header__brand__mark desktop"
            width={184}
          />
        </Link>
      </div>
      <div className="header__navigation-container desktop">
        <div className="header__navigation-items">
          {navigation.map((navData: NavigationNavData, outerIndex: number) => {
            return (
              <NavItemDesktop
                navData={navData.content}
                featured={navData.featured}
                key={outerIndex}
                mainTab={outerIndex}
              />
            )
          })}
        </div>
        <div className="header__navigation-login-container">
          {OtherNavInfo.navInfo.map((item, index: number) => {
            const { nav, link, internal } = item
            return internal ? (
              <Link className="header__link" to={link} key={index}>
                <h4>{nav}</h4>
              </Link>
            ) : (
              <a
                href={link}
                target="_blank"
                rel="noopener nonreferer"
                key={index}
                className="header__link"
              >
                <h4>{nav}</h4>
              </a>
            )
          })}
          <Link to={OtherNavInfo.demo.link} className="header__navigation-demo">
            {OtherNavInfo.demo.nav}
          </Link>
        </div>
      </div>
    </AppHeaderDesktopContainerStyled>
  )
}

export default AppHeaderDesktopContainer

const AppHeaderDesktopContainerStyled = styled.div`
  /* z-index: 2; */
  height: 100%;
  background: var(--color-content-bg);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header__brand {
    margin: ${rem(20)};
  }

  .header__navigation-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    .header__link {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      color: var(--color-gray800);
      text-decoration: none;
    }
  }

  .header__navigation-items {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .header__navigation-login-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    gap: ${rem(14)};
    height: 100%;
    a {
      cursor: pointer;
      &:hover {
        color: var(--color-primary);
      }
    }
  }
  .header__navigation-demo {
    height: ${rem(48)};
    padding: ${rem(20)};
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    border: 1px solid var(--color-primary);
    border-radius: var(--rounded);

    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
    margin-left: ${rem(20)};
    margin-right: ${rem(16)};

    @media ${media.lgUp} {
      margin-right: ${rem(24)};
    }
  }

  .desktop {
    align-items: center;
    height: 100%;
    display: none;

    @media ${media.stUp} {
      display: flex;
    }
  }

  .mobile {
    flex-direction: column;
    height: 100%;

    @media ${media.stUp} {
      display: none;
    }
  }
`
