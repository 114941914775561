import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import { IconArrowRight, RegBody, SemiBdTitle5 } from "@sr/gatsby-theme-ui"

import { type NavigationSubSectionContent } from "../navData"

export interface AppHeaderMobileAccordionGroupProps
  extends NavigationSubSectionContent {
  active: boolean
}

const AppHeaderMobileAccordionGroup = ({
  header,
  nav,
  link,
  iconAbove,
  navigationItems,
  active,
}: AppHeaderMobileAccordionGroupProps) => {
  return (
    <AppHeaderMobileAccordionGroupStyled active={active}>
      <div className="header__mobile-accordion-group__container">
        <motion.div
          initial={{ height: active ? "auto" : 0 }}
          animate={{ height: active ? "auto" : 0 }}
          transition={{ duration: 0.4 }}
        >
          <div className="header__mobile-accordion-group__container-inner">
            {header && (
              <SemiBdTitle5 className="header__mobile-accordion-group__title">
                {header}
              </SemiBdTitle5>
            )}
            {navigationItems.map((navigationItem: any, i: number) => {
              return (
                <div
                  className="header__mobile-accordion-group__link-container"
                  key={i}
                >
                  {navigationItem?.icon && navigationItem.icon()}
                  <Link
                    className="header__mobile-accordion-group__link"
                    to={navigationItem.link}
                    style={{ color: "greem", fontSize: "8px" }}
                  >
                    <RegBody>{navigationItem.nav}</RegBody>
                  </Link>
                </div>
              )
            })}
            {nav && link && (
              <div
                className={`header__mobile-accordion-group-view-all ${
                  iconAbove ? "icon-above" : ""
                }`}
              >
                <Link to={link}>{nav}</Link>
                <IconArrowRight color="var(--color-primary)" size={16} />
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </AppHeaderMobileAccordionGroupStyled>
  )
}

export default AppHeaderMobileAccordionGroup

interface AppHeaderMobileAccordionGroupStyledSC {
  active: boolean
}

const AppHeaderMobileAccordionGroupStyled = styled.div<AppHeaderMobileAccordionGroupStyledSC>`
  overflow: hidden;

  .header__mobile-accordion-group__container-inner {
    display: flex;
    flex-direction: column;
    gap: ${rem(16)};
    padding: ${rem(24)} 0 ${rem(24)} ${rem(48)};
    background-color: var(--color-gray050);
    border: 1px solid var(--color-gray100);
  }

  .header__mobile-accordion-group__title {
    color: var(--color-primary);
  }

  .header__mobile-accordion-group__link-container {
    display: flex;
    gap: ${rem(16)};

    .header__mobile-accordion-group__link {
      color: var(--color-gray800);
    }
  }

  .header__mobile-accordion-group-view-all {
    display: flex;
    align-items: center;
    gap: ${rem(8)};

    a {
      color: var(--color-primary);
    }
  }

  .header__mobile-accordion-group-view-all.icon-above {
    margin-left: ${rem(41)};
  }
`
